import createRouter from 'router5';
import loggerPlugin from 'router5-plugin-logger';
import listenersPlugin from 'router5-plugin-listeners';
import browserPlugin from 'router5-plugin-browser';
import routes from '~/pages/routes';
import { getComponent } from './utils';
import asyncComponentLoader from './middlewares/async-component-loader';
import asyncI18nLoader from './middlewares/async-i18n-loader';
import graphqlPrefetch from './middlewares/graphql-prefetch';
import hasPermissions from './middlewares/has-permissions';
import googleAnalytics from './middlewares/google-analytics';
import carrotQuest from './middlewares/carrot-quest';

export default function configureRouter() {
  const router = createRouter(routes, {
    // defaultRoute: 'welcome',
    defaultParams: {},
    strictQueryParams: true
  });

  router.usePlugin(browserPlugin({}));
  router.usePlugin(listenersPlugin());

  if (process.env.NODE_ENV === 'development') {
    router.usePlugin(loggerPlugin);
  }

  router.useMiddleware(hasPermissions(routes, router));
  router.useMiddleware(asyncI18nLoader(routes));
  router.useMiddleware(asyncComponentLoader(routes));
  router.useMiddleware(graphqlPrefetch(routes, router));

  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    router.useMiddleware(googleAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID));
  }

  if (process.env.REACT_APP_CARROTQUEST_JS_CODE_ID && process.env.REACT_APP_CARROTQUEST_JS_USER_AUTH_KEY) {
    router.useMiddleware(
      carrotQuest(process.env.REACT_APP_CARROTQUEST_JS_CODE_ID, process.env.REACT_APP_CARROTQUEST_JS_USER_AUTH_KEY)
    );
  }

  router.setDependency('getComponent', getComponent);

  return router;
}

import getProfileDetails from '~/_graphql/queries/get-profile-details.graphql';
import profile from './profile/route';
import help from './help/route';
import news from './news/route';
import flight from './flight/route';
import statistic from './statistic/route';
import flightForm from './flight-form/route';
import campaign from './campaign/route';
import advertiser from './advertiser/route';
import flightList from './flight-list/route';
import dashboard from './dashboard/route';

export default {
  name: 'trading-desk',
  path: '/trading-desk',
  forwardTo: 'trading-desk.profile.details',
  i18n: ['trading-desk/main-menu'],
  graphqlPrefetch: () => [{ query: getProfileDetails }],
  loadComponent: () => import('./index'),
  children: [profile, help, news, flight, statistic, flightForm, campaign, advertiser, flightList, dashboard]
};

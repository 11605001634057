const STATUSES = {
  attention: 'Attention',
  active: 'Active',
  planned: 'Planned',
  finish: 'Finish',
  all: 'All'
};

const encodeFunction = ({
  archived,
  deliveryStrategy,
  status,
  endDate,
  startDate,
  dateFilterType,
  searchText,
  id,
  backUrl,
  copyForm
}) => {
  const result = {
    fds: deliveryStrategy,
    fs: status,
    ft: searchText,
    wa: archived,
    cf: copyForm,
    id: id || null,
    bu: backUrl || null
  };

  if (dateFilterType === 'period') {
    result.fps = startDate;
    result.fpe = endDate;
  } else if (dateFilterType === 'start') {
    result.fsd = startDate;
  } else if (dateFilterType === 'end') {
    result.fed = endDate;
  }
  return result;
};

const decodeFunction = ({ fds, fpe, fps, fsd, fed, fs, ft, wa, cf, id, bu }) => {
  const result = {
    archived: wa === 'true',
    deliveryStrategy: fds,
    status: STATUSES[fs] || fs,
    endDate: fpe || fed,
    startDate: fps || fsd,
    searchText: ft,
    copyForm: cf === 'true',
    id,
    backUrl: bu
  };

  if (fpe && fps) {
    result.dateFilterType = 'period';
  } else if (fsd) {
    result.dateFilterType = 'start';
  } else if (fed) {
    result.dateFilterType = 'end';
  } else {
    result.dateFilterType = 'all';
  }
  return result;
};

export default {
  name: 'form',
  path: '/?fds&fs&ft&wa&fps&fpe&fsd&fed&bu&cf',
  loadComponent: () => import('./index'),
  i18n: ['trading-desk/campaign'],
  encodeParams: encodeFunction,
  decodeParams: decodeFunction
};

// @flow

import details from './details/route';
import subscriptions from './subscriptions/route';
import accesses from './accesses/route';

export default {
  name: 'profile',
  path: '/profile',
  forwardTo: 'trading-desk.profile.details',
  loadComponent: () => import('./index'),
  children: [details, subscriptions, accesses]
};

// @flow

const React = require('react');
const NotificationContainer = require('./NotificationContainer').default;
const Constants = require('./constants').default;

require('./index.scss');

type NotificationSystemProps = {
  allowHTML?: boolean
};

type NotificationSystemState = {
  notifications: Array<any>
};

class NotificationSystem extends React.Component<
  NotificationSystemProps,
  NotificationSystemState
> {
  static defaultProps = {
    allowHTML: false
  };

  constructor(props: NotificationSystemProps) {
    super(props);

    this.uid = 3400;
    this.isMount = false;

    this.state = {
      notifications: []
    };
  }

  componentDidMount() {
    this.isMount = true;
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  getNotificationRef = (notification: any) => {
    const self = this;
    let foundNotification = null;

    // eslint-disable-next-line react/no-string-refs
    Object.keys(this.refs).forEach(container => {
      if (container.indexOf('container') > -1) {
        Object.keys(self.refs[container].refs).forEach(newNotification => {
          const uid = notification.uid ? notification.uid : notification;
          if (newNotification === `notification-${uid}`) {
            // NOTE: Stop iterating further and return the found notification.
            // Since UIDs are uniques and there won't be another notification found.
            foundNotification = self.refs[container].refs[newNotification];
          }
        });
      }
    });

    return foundNotification;
  };

  didNotificationRemoved = (uid: number) => {
    const {notifications: notificationsFromState} = this.state;

    let notification;

    const notifications = notificationsFromState.filter(toCheck => {
      if (toCheck.uid === uid) {
        notification = toCheck;
        return false;
      }
      return true;
    });

    if (this.isMount) {
      this.setState({notifications});
    }

    if (notification && notification.onRemove) {
      notification.onRemove(notification);
    }
  };

  addNotification = (notification: any) => {
    const newNotification = {
      ...Constants.notification,
      ...notification
    };

    const {notifications} = this.state;

    let i;

    if (!newNotification.level) {
      throw new Error('notification level is required.');
    }

    if (Object.keys(Constants.levels).indexOf(newNotification.level) === -1) {
      throw new Error(`'${newNotification.level}' is not a valid level.`);
    }

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(newNotification.autoDismiss)) {
      throw new Error("'autoDismiss' must be a number.");
    }

    // Some preparations
    newNotification.level = newNotification.level.toLowerCase();
    newNotification.autoDismiss = parseInt(newNotification.autoDismiss, 10);

    newNotification.uid = newNotification.uid || this.uid;
    newNotification.ref = `notification-${newNotification.uid}`;
    this.uid += 1;

    // do not add if the notification already exists based on supplied uid
    for (i = 0; i < notifications.length; i += 1) {
      if (notifications[i].uid === newNotification.uid) {
        return false;
      }
    }

    notifications.push(newNotification);

    if (typeof newNotification.onAdd === 'function') {
      notification.onAdd(newNotification);
    }

    this.setState({notifications});

    return newNotification;
  };

  removeNotification = (notification: any) => {
    const foundNotification = this.getNotificationRef(notification);
    return foundNotification && foundNotification.hideNotification();
  };

  editNotification = (notification: any, newNotification: any) => {
    let foundNotification = null;
    // NOTE: Find state notification to update by using
    // `setState` and forcing React to re-render the component.
    const uid = notification.uid ? notification.uid : notification;

    const {notifications} = this.state;

    const newNotifications = notifications.filter(stateNotification => {
      if (uid === stateNotification.uid) {
        foundNotification = stateNotification;
        return false;
      }

      return true;
    });

    if (!foundNotification) {
      return;
    }

    newNotifications.push({
      ...foundNotification,
      ...newNotification
    });

    this.setState({
      notifications: newNotifications
    });
  };

  clearNotifications = () => {
    const self = this;
    // eslint-disable-next-line react/no-string-refs
    Object.keys(this.refs).forEach(container => {
      if (container.indexOf('container') > -1) {
        Object.keys(self.refs[container].refs).forEach(newNotification => {
          self.refs[container].refs[newNotification].hideNotification();
        });
      }
    });
  };

  isMount: boolean;

  uid: number;

  render() {
    const {allowHTML} = this.props;
    const {notifications} = this.state;
    return (
      <div className='notification-system'>
        <NotificationContainer
          // eslint-disable-next-line react/no-string-refs
          ref='container'
          key='container'
          notifications={notifications}
          onRemove={this.didNotificationRemoved}
          allowHTML={allowHTML}
        />
      </div>
    );
  }
}

export default NotificationSystem;

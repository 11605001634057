// @flow

import getAgency from './_graphql/queries/get-agency.graphql';

export default {
  name: 'accesses',
  path: '/accesses',
  graphqlPrefetch: () => [{ query: getAgency, fetchPolicy: 'network-only' }],
  i18n: ['trading-desk/profile/accesses', 'trading-desk/main-menu'],
  loadComponent: () => import('./index'),
  hasPermissions: (permissions: Array<string>) => permissions.includes('SYSTEM_AGENCY_ADMIN_VIEW')
};

import tradingDesk from './trading-desk/route';
import welcome from './welcome/route';
import auth from './auth/route';
import logout from './logout/route';

export default [
  tradingDesk,
  welcome,
  auth,
  logout
  // login,
  // ...
];

// @flow

import * as React from 'react';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import NotificationItem from './NotificationItem';

type Props = {
  notifications: Array<any>,
  allowHTML?: boolean,
  onRemove: (id: number) => void,
  children?: React.Node
};

export default class NotificationContainer extends React.Component<Props> {
  static defaultProps = {
    allowHTML: false,
    children: null
  };

  render() {
    const {notifications, allowHTML, onRemove, children} = this.props;

    const notificationsList = notifications.map(notification => (
      <CSSTransition
        key={`alert-transition-${notification.uid}`}
        timeout={{enter: 300, exit: 600}}
        classNames='notification-system__alert'
      >
        <NotificationItem
          ref={`alert-${notification.uid}`}
          key={notification.uid}
          notification={notification}
          onRemove={onRemove}
          allowHTML={allowHTML}
        >
          {children}
        </NotificationItem>
      </CSSTransition>
    ));

    return <TransitionGroup>{notificationsList}</TransitionGroup>;
  }
}

import { gaInit, gaPageView } from '~/_utils/google-analytics';
import getProfileDetails from '~/_graphql/queries/get-profile-details.graphql';
import apolloClient from '../../apollo';

const googleAnalytics = googleAnalyticsId => () => (toState, fromState) =>
  new Promise(resolve => {
    if (!fromState) {
      gaInit({
        googleAnalyticsId
      });
    }

    apolloClient.query({ query: getProfileDetails }).then(
      ({
        data: {
          login: { id, username }
        }
      }) => {
        gaPageView({
          userId: id,
          userName: username
        });
        resolve();
      },
      () => {
        gaPageView({
          userId: '',
          userName: ''
        });
        resolve();
      }
    );
  });

export default googleAnalytics;

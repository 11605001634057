// @flow

import getAnnouncementList from './_graphql/queries/get-anons-list.graphql';

export default {
  name: 'news',
  path: '/news',
  children: [
    {
      name: 'newsWithId',
      path: '/:id'
    }
  ],
  loadComponent: () => import('./index'),
  graphqlPrefetch: () => [{ query: getAnnouncementList, fetchPolicy: 'network-only' }],
  i18n: ['trading-desk/news']
};

// @flow

import {
  ApolloLink,
  Observable,
  fallbackHttpConfig,
  selectURI,
  selectHttpOptionsAndBody,
  serializeFetchParameter,
  parseAndCheckHttpResponse,
  createSignalIfSupported
} from '@apollo/client';
import { extractFiles } from 'extract-files';

/**
 * Transform file path ('variables.example.one.two.three')
 * to the next form: 'variables[example][one][two][three]
 * @param {String} path
 *
 * @returns {String}
 */
function transformPath(path) {
  const splitPath = path.split('.');
  return [splitPath[0], ...splitPath.slice(1).map(segment => `[${segment}]`)].join('');
}

/**
 * Overwritten npm module "apollo-upload-client"
 * {@link https://github.com/jaydenseric/apollo-upload-client }
 * Implements custom Leto multipart request format
 */
export const createUploadLink = ({
  uri: fetchUri = '/graphql',
  fetch: linkFetch = fetch,
  fetchOptions,
  credentials,
  headers,
  includeExtensions
}: {
  uri: string,
  fetch?: fetch,
  fetchOptions?: {},
  credentials?: {},
  headers?: {},
  includeExtensions?: {}
} = {}) => {
  const linkConfig = {
    http: { includeExtensions },
    options: fetchOptions,
    credentials,
    headers
  };

  return new ApolloLink(operation => {
    const uri = selectURI(operation, fetchUri);
    const context = operation.getContext();
    const contextConfig = {
      http: context.http,
      options: context.fetchOptions,
      credentials: context.credentials,
      headers: context.headers
    };

    const { options, body } = selectHttpOptionsAndBody(operation, fallbackHttpConfig, linkConfig, contextConfig);

    const { files } = extractFiles(body);
    const payload = serializeFetchParameter(body, 'Payload');

    if (files.size) {
      // Automatically set by fetch when the body is a FormData instance.
      delete options.headers['content-type'];

      // Leto multipart request format:
      options.body = new FormData();

      let indexKey = 0;
      files.forEach(([path], file) => {
        const fieldName = `file${indexKey}`;
        options.body.append(transformPath(path), fieldName);
        options.body.append(fieldName, file, file.name);
        indexKey++;
      });

      options.body.append('query', body.query || {});
      options.body.append('operationName', body.operationName || '');

      // GraphQL multipart request spec:
      // https://github.com/jaydenseric/graphql-multipart-request-spec
      /*
      options.body = new FormData();
      options.body.append('operations', payload);
      options.body.append(
        'map',
        JSON.stringify(
          files.reduce((map, { path }, index) => {
            map[`${index}`] = [path];
            return map;
          }, {}),
        ),
      );
      files.forEach(({ file }, index) =>
        options.body.append(index, file, file.name),
      );
      */
    } else options.body = payload;

    return new Observable(observer => {
      // Allow aborting fetch, if supported.
      const { controller, signal } = createSignalIfSupported();
      if (controller) options.signal = signal;

      linkFetch(uri, options)
        .then(response => {
          // Forward the response on the context.
          operation.setContext({ response });
          return response;
        })
        .then(parseAndCheckHttpResponse(operation))
        .then(result => {
          observer.next(result);
          observer.complete();
        })
        .catch(error => {
          if (error.name === 'AbortError')
            // Fetch was aborted.
            return;

          if (error.result && error.result.errors)
            // There is a GraphQL result to forward.
            observer.next(error.result);

          observer.error(error);
        });

      // Cleanup function.
      return () => {
        // Abort fetch.
        if (controller) controller.abort();
      };
    });
  });
};

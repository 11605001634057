import { getActivatedRoutes } from '../utils';

const asyncComponentLoader = routes => () => (toState, fromState) => {
  const onActivateHandlers = getActivatedRoutes(routes, toState, fromState)
    .filter(route => !route.component || route.dynamicComponent)
    .map(
      route =>
        new Promise((resolve, reject) => {
          if (route.loadComponent) {
            route
              .loadComponent(toState, fromState)
              .then(component => Object.assign(route, { component: component.default }))
              .then(resolve)
              .catch(reject);
          } else {
            resolve(Object.assign(route, { component: null }));
          }
        })
    );

  return Promise.all(onActivateHandlers);
};

export default asyncComponentLoader;

import React from 'react';
import NotificationSystem from './_components/notification-system';

let notificationManager = null; // eslint-disable-line import/no-mutable-exports

const NotificationComponent = (
  <NotificationSystem
    ref={el => {
      notificationManager = el;
    }}
  />
);

export { notificationManager, NotificationComponent };

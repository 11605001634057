/* eslint-disable import/no-cycle */

import apolloClient from '../index';
import checkProfile from './_graphql/queries/check-profile.graphql';

const typeDefs = `
  type Session {
    verify: Boolean
  }

  type Query {
    session: Session
  }
`;

export default {
  typeDefs,
  resolvers: {
    Query: {
      session: () =>
        new Promise(resolve => {
          apolloClient.query({ query: checkProfile, fetchPolicy: 'network-only' }).then(
            () => {
              resolve({
                verify: true,
                __typename: 'Session'
              });
            },
            () => {
              resolve({
                verify: false,
                __typename: 'Session'
              });
            }
          );
        })
    }
  }
};

// @flow

import getPermissions from '~/_graphql/queries/get-permissions.graphql';
import getNotificationSettings from './_graphql/queries/get-notification-settings.graphql';

export default {
  name: 'subscriptions',
  path: '/subscriptions',
  graphqlPrefetch: () => [
    { query: getPermissions, fetchPolicy: 'network-only' },
    { query: getNotificationSettings, fetchPolicy: 'network-only' }
  ],
  i18n: ['trading-desk/profile/subscriptions', 'trading-desk/main-menu'],
  loadComponent: () => import('./index')
};

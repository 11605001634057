// @flow

import getProfileDetails from '~/_graphql/queries/get-profile-details.graphql';

export default {
  name: 'details',
  path: '/details',
  graphqlPrefetch: () => [{ query: getProfileDetails, fetchPolicy: 'network-only' }],
  i18n: ['trading-desk/profile/details', 'trading-desk/main-menu'],
  loadComponent: () => import('./index')
};

// @flow

import type { Route } from '~/modules/router/types.flow';

import moment from 'moment';
import { LOAD_PER_PAGE } from '~/_utils/statistic-queries';
import getProfileDetails from '~/_graphql/queries/get-profile-details.graphql';
import getAgencyItem from './_graphql/queries/get-agency-item.graphql';
import getAgencyList from './_graphql/queries/get-agency-list.graphql';
import getAdvertiserItem from './_graphql/queries/get-advertiser-item.graphql';
import getAdvertiserList from './_graphql/queries/get-advertiser-list.graphql';
import getCampaignItem from './_graphql/queries/get-campaign-item.graphql';
import getCampaignList from '../../../_graphql/queries/get-campaign-list.graphql';
import getFlightItem from './_graphql/queries/get-flight-item.graphql';
import getFlightList from '../../../_graphql/queries/get-flight-list.graphql';
import getCreativeList from './_graphql/queries/get-creative-list.graphql';
import getStatisticTypes from './_graphql/queries/get-statistic-types.graphql';
import getStatisticAvailabilityStatus from './_graphql/queries/get-statistic-availability-status.graphql';
import getStatisticDefaultReportsAvailabilityStatus from './_graphql/queries/get-statistic-default-reports-availability-status.graphql';
import getAvailableBidTypes from './_graphql/queries/get-bid-types-list.graphql';
import isNil from 'lodash/isNil';

const isStatisticRoute = (route: Route) => route && route.name && route.name.includes('trading-desk.statistic');

const filterEncodeParams = ({
  type,
  agencyId,
  advertiserId,
  campaignId,
  flightId,
  creativeId,
  period,
  group,
  bidType,
  source
}: {
  type: string,
  agencyId: string,
  advertiserId: string,
  campaignId: string,
  flightId: string,
  creativeId: string,
  period: { from: Date, to: Date },
  group: string,
  bidType: string,
  source: string
}) => ({
  type,
  ag: agencyId,
  ad: advertiserId,
  ca: campaignId,
  fl: flightId,
  cr: creativeId,
  df: period && period.from && moment(period.from.getTime()).format('YYYY-MM-DD'),
  dt: period && period.to && moment(period.to.getTime()).format('YYYY-MM-DD'),
  gr: group,
  bt: bidType,
  sr: source
});

const filterDecodeParams = ({
  type,
  ag,
  ad,
  ca,
  fl,
  cr,
  df,
  dt,
  gr,
  bt,
  sr
}: {
  type: string,
  ag: string,
  ad: string,
  ca: string,
  fl: string,
  cr: string,
  df: string,
  dt: string,
  gr: string,
  bt: string,
  sr: string
}) => ({
  type,
  agencyId: ag,
  advertiserId: ad,
  campaignId: ca,
  flightId: fl,
  creativeId: cr,
  period: df &&
    dt && {
      from: new Date(df),
      to: new Date(dt)
    },
  group: gr,
  bidType: bt,
  source: sr || 'Billing'
});

const filterGraphqlPrefetch = (toState: Route, fromState: Route) => {
  const result = [];
  if (!isStatisticRoute(fromState)) {
    const { params } = toState;

    result.push({
      query: getProfileDetails
    });

    result.push({
      query: getAgencyList,
      variables: { pagination: { page: 1, perPage: LOAD_PER_PAGE } },
      fetchPolicy: 'network-only'
    });

    if (typeof params.agencyId !== 'undefined') {
      result.push({
        query: getAgencyItem,
        variables: {
          id: params.agencyId
        },
        fetchPolicy: 'network-only'
      });
      result.push({
        query: getAdvertiserList,
        variables: {
          agencyId: params.agencyId,
          pagination: {
            page: 1,
            perPage: LOAD_PER_PAGE
          }
        },
        fetchPolicy: 'network-only'
      });
    }

    if (typeof params.agencyId !== 'undefined' && params.advertiserId) {
      result.push({
        query: getAdvertiserItem,
        variables: {
          agencyId: params.agencyId,
          id: params.advertiserId
        },
        fetchPolicy: 'network-only'
      });
      result.push({
        query: getCampaignList,
        variables: {
          filter: {
            advertiserId: params.advertiserId
          },
          pagination: {
            page: 1,
            perPage: LOAD_PER_PAGE
          }
        },
        fetchPolicy: 'network-only'
      });
      result.push({
        query: getStatisticDefaultReportsAvailabilityStatus,
        variables: {
          advertiser: params.advertiserId,
          campaign: params.campaignId,
          period: {
            start: params.period?.from,
            end: params.period?.to
          }
        },
        fetchPolicy: 'network-only'
      });
      if (params.campaignId) {
        result.push({
          query: getStatisticAvailabilityStatus,
          variables: {
            advertiser: params.advertiserId,
            campaign: params.campaignId,
            period: {
              start: params.period?.from,
              end: params.period?.to
            }
          },
          fetchPolicy: 'network-only'
        });
      }
    }

    if (params.campaignId) {
      result.push({
        query: getCampaignItem,
        variables: {
          id: params.campaignId
        },
        fetchPolicy: 'network-only'
      });
      result.push({
        query: getFlightList,
        variables: {
          campaignId: params.campaignId
        },
        fetchPolicy: 'network-only'
      });
    }

    if (params.flightId) {
      result.push({
        query: getFlightItem,
        variables: {
          id: params.flightId
        },
        fetchPolicy: 'network-only'
      });
      result.push({
        query: getCreativeList,
        variables: {
          flightId: params.flightId
        },
        fetchPolicy: 'network-only'
      });
    }

    const objId = params.creativeId || params.flightId || params.campaignId;
    if (objId) {
      result.push({
        query: getStatisticTypes,
        variables: { id: objId },
        fetchPolicy: 'network-only'
      });
    }

    const canFetchBidTypes = !(isNil(params.flightId) && isNil(params.campaignId) && isNil(params.advertiserId));

    if (canFetchBidTypes) {
      result.push({
        query: getAvailableBidTypes,
        variables: {
          id: params.flightId || params.campaignId || params.advertiserId
        }
      });
    }
  }
  return result;
};

export default {
  name: 'statistic',
  path: '/statistic',
  forwardTo: 'trading-desk.statistic.welcome',
  loadComponent: () => import('./index'),
  i18n: ['trading-desk/statistic'],
  children: [
    {
      name: 'welcome',
      path: '/',
      loadComponent: () => import('./welcome'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'date',
      path: '/date?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/date/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'campaign',
      path: '/campaign?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/campaign/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'flight',
      path: '/flight?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/flight/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'creative',
      path: '/creative?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/creative/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'website',
      path: '/website?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/website/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'geo',
      path: '/geo?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/geo/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'geo-targeted',
      path: '/geo-targeted?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/geo-targeted/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'social-demography',
      path: '/social-demography?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/social-demography/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    },
    {
      name: 'segment',
      path: '/segment?ag&ad&ca&fl&cr&df&dt&type&gr&bt&sr&hash',
      encodeParams: filterEncodeParams,
      decodeParams: filterDecodeParams,
      loadComponent: () => import('./report/segment/index'),
      graphqlPrefetch: filterGraphqlPrefetch
    }
  ]
};

import React, { createContext, Component } from 'react';

export const initialStore = {};

export const Store = createContext(initialStore);

export const connect = WrappedComponent =>
  class Connect extends Component {
    static contextType = Store;

    render() {
      const { props, context } = this;
      return <WrappedComponent {...props} __STORE__={Object.assign({}, context)} />;
    }
  };

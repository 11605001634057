import apolloClient from '../../apollo';
import { getActivatedRoutes } from '../utils';
import graphqlExceptionHandling from './graphql-exception-handling';

const graphqlPrefetch = (routes, router) => () => (toState, fromState) => {
  const activatedRoutes = getActivatedRoutes(routes, toState, fromState);

  if (activatedRoutes.length === 0) {
    return Promise.resolve();
  }

  const onActivateHandlers = activatedRoutes
    .map(route => {
      let result = [];
      if (route.graphqlPrefetch) {
        result = route.graphqlPrefetch(toState, fromState);
      }
      return result;
    })
    .reduce((a, b) => a.concat(b))
    .map(query => apolloClient.query(query));

  const allHandlers = Promise.all(onActivateHandlers);
  allHandlers.catch(({ graphQLErrors }) => graphqlExceptionHandling({ graphQLErrors, router, toState }));

  return allHandlers;
};

export default graphqlPrefetch;

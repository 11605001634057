// @flow

const STATUSES = {
  attention: 'Attention',
  active: 'Active',
  planned: 'Planned',
  finish: 'Finish',
  all: 'All'
};

const GROUPED = {
  user: 'User',
  campaign: 'Campaign'
};

export default {
  name: 'flight-list',
  path:
    '/flight-list?filterDeliveryStrategy&filterStatus&filterText&groupBy&withArchive&filterPeriodStart&filterPeriodEnd&filterStartDate&filterEndDate',
  forwardTo: 'trading-desk.flight-list',
  i18n: ['trading-desk/flight-list'],
  loadComponent: () => import('./index'),
  encodeParams: ({
    // то что пришло из стейта
    groupBy,
    archived,
    deliveryStrategy,
    status,
    endDate,
    startDate,
    dateFilterType,
    searchText
  }) => {
    const result = {
      groupBy,
      filterDeliveryStrategy: deliveryStrategy,
      filterStatus: status,
      filterText: searchText,
      withArchive: archived
    };

    if (dateFilterType === 'period') {
      result.filterPeriodStart = startDate;
      result.filterPeriodEnd = endDate;
    } else if (dateFilterType === 'start') {
      result.filterStartDate = startDate;
    } else if (dateFilterType === 'end') {
      result.filterEndDate = endDate;
    }
    return result;
  },
  decodeParams: ({
    // то что пришло из url
    groupBy,
    filterDeliveryStrategy,
    filterPeriodEnd,
    filterPeriodStart,
    filterStartDate,
    filterEndDate,
    filterStatus,
    filterText,
    withArchive
  }) => {
    const result = {
      groupBy: GROUPED[groupBy] || groupBy,
      archived: withArchive === 'true',
      deliveryStrategy: filterDeliveryStrategy,
      status: STATUSES[filterStatus] || filterStatus,
      endDate: filterPeriodEnd || filterEndDate,
      startDate: filterPeriodStart || filterStartDate,
      searchText: filterText
    };

    if (filterPeriodEnd && filterPeriodStart) {
      result.dateFilterType = 'period';
    } else if (filterStartDate) {
      result.dateFilterType = 'start';
    } else if (filterEndDate) {
      result.dateFilterType = 'end';
    } else {
      result.dateFilterType = 'all';
    }
    return result;
  }
};

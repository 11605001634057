// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withTranslation } from 'react-i18next';
import { Button, Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router5';

import logoImg from '~/_assets/sber-seller_logoH.svg';
import { ReactComponent as Image403 } from './images/403.svg';
import { ReactComponent as Image404 } from './images/404.svg';
import { ReactComponent as Image500 } from './images/500.svg';
import { ReactComponent as Image503 } from './images/503.svg';
import './index.scss';

const getImage = code => {
  switch (`${code}`) {
    case '403':
      return Image403;
    case '404':
      return Image404;
    case '500':
      return Image500;
    case '503':
      return Image503;
    default:
      return Image500;
  }
};

class ErrorPage extends React.Component {
  render() {
    const { code = 500, errorText, t } = this.props;
    const Image = getImage(code);
    return (
      <Container className={`error_${code} page_error`}>
        <Helmet>
          <title>{errorText || t(`errors:${code}.title`)}</title>
        </Helmet>

        <Row className='page_error__row-logo'>
          <Col className='text-center'>
            <Link routeName='trading-desk' routeOptions={{ reload: true }}>
              <img src={logoImg} alt={t('app:title')} width='200' />
            </Link>
          </Col>
        </Row>
        <Row className='page_error__row-description'>
          <Col className='text-center'>
            <h1 className='mt-4'>{errorText || t(`errors:${code}.title`)}</h1>
            <p className='text-secondary'>
              <Trans i18nKey={`errors:${code}.description`} t={t}>
                <a href={`mailto:${t('app:email')}`}>{t('app:email')}</a>
                <a href={`tel:${t('app:phone')}`}>{t('app:phone')}</a>
              </Trans>
            </p>
          </Col>
        </Row>
        <Row className='page_error__row-buttons'>
          <Col className='text-center'>
            <Button
              tag={Link}
              routeName='trading-desk.flight-list'
              outline
              color='primary'
              routeOptions={{ reload: true }}
            >
              {t(`errors:${code}.come back to main page`)}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation(['errors', 'app'])(ErrorPage);

// @flow
import getProfileDetails from '~/_graphql/queries/get-profile-details.graphql';
import getCampaignData from '../graphql/queries/get-campaign.graphql';
import top100 from './top100/route';
import old from './old/route';

const filterGraphqlPrefetch = toState => {
  const result = [];
  const { params } = toState;

  result.push({
    query: getProfileDetails,
    fetchPolicy: 'network-only'
  });

  if (params.id) {
    result.push({
      query: getCampaignData,
      variables: {
        id: params.id
      },
      fetchPolicy: 'network-only'
    });
  }

  return result;
};

export default {
  name: 'mass-edit',
  path: '/mass-edit',
  graphqlPrefetch: filterGraphqlPrefetch,
  loadComponent: () => import('./index'),
  children: [top100, old]
};

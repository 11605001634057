/* eslint-disable */
export const gaInit = ({googleAnalyticsId}) => {
  if (AntiMattr == null || typeof AntiMattr != 'object') {
    var AntiMattr = (window.AntiMattr = {});
  }
  AntiMattr.GoogleAnalytics = {};

  AntiMattr.GoogleAnalytics.onGatLoad = function(callback) {
    if (typeof ga != 'undefined') {
      callback();
    } else {
      var listener = this;
      setTimeout(function() {
        listener.onGatLoad(callback);
      }, 100);
    }
  };

  /**
   * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events
   *
   * @param string   tracker
   * @param string   category
   * @param string   action
   * @param string   label [optional]
   * @param integer  value [optional and dependent on label]
   * @param bool     nonInteraction
   * @param function callback Execute callback when ga(send) done
   */
  AntiMattr.GoogleAnalytics.notifyEvent = function(
    tracker,
    category,
    action,
    label,
    value,
    nonInteraction,
    callback
  ) {
    var eventTracker = tracker,
      eventCategory = String(category),
      eventAction = String(action),
      eventLabel = null,
      eventValue = null,
      eventNonInteraction = 0,
      eventCallback = null;

    if (typeof label != 'undefined') {
      eventLabel = String(label);
    }
    if (typeof value != 'undefined') {
      eventValue = parseInt(value);
    }
    if (typeof nonInteraction != 'undefined') {
      eventNonInteraction = Number(nonInteraction);
    }
    if (typeof callback != 'undefined') {
      eventCallback = callback;
    }

    if (typeof ga == 'undefined') {
      var listener = this;
      setTimeout(function() {
        listener.notifyEvent(
          eventTracker,
          eventCategory,
          eventAction,
          eventLabel,
          eventValue,
          eventNonInteraction,
          eventCallback
        );
      }, 100);
      return;
    }

    ga(tracker + 'send', 'event', {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
      hitCallback: eventCallback,
      nonInteraction: eventNonInteraction
    });
  };

  (function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(
    window,
    document,
    'script',
    '//www.google-analytics.com/analytics.js',
    'ga'
  );

  // Upgrade documentation
  // @see https://developers.google.com/analytics/devguides/collection/upgrade/reference/gajs-analyticsjs
  // Start Tracker: default

  // Configuration
  var agenciesData;
  var config = (agenciesData = {}),
    userInfo = {userId: '', userName: ''};
  // Will be an empty string if includeNamePrefix = false
  config['name'] = '';
  config['cookieDomain'] = window.location.hostname;
  config['allowAnchor'] = true;
  config['allowLinker'] = true;
  config['siteSpeedSampleRate'] = 5;

  agenciesData = [];

  ga('create', googleAnalyticsId, 'auto', config);

  ga('set', 'dimension3', Object.keys(agenciesData).join(', '));
  ga('set', 'dimension4', Object.values(agenciesData).join(', '));

  // Plugins
  // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/plugins

  // Custom Variables on pageview
  // @see http://misterphilip.com/universal-analytics/migration/variables

  // Classic Transactions
  // @see https://developers.google.com/analytics/devguides/collection/upgrade/reference/gajs-analyticsjs#ecommerce
  // Note: Classic Transaction do have a native send method, so they do not need to be initialized prior to page views

  // Events
  // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/events

  // End Tracker: default
};

export const gaPageView = ({userId, userName}) => {
  // Pageview
  // @see https://developers.google.com/analytics/devguides/collection/analyticsjs/pages

  if (!window.ga) {
    return;
  }

  ga('set', 'userId', userId);
  ga('set', 'dimension1', userId);
  ga('set', 'dimension2', userName);

  if (window.location.hash) {
    ga('set', 'page', window.location.pathname + window.location.hash);
  } else {
    ga('set', 'page', window.location.pathname);
  }
  ga('send', 'pageview');
};
/* eslint-enable */

import * as React from 'react';
import classNames from 'classnames';
import {Toast, ToastHeader} from 'reactstrap';
import styles from './index.module.scss';

type CustomToastProps = {
  children: React.Node,
  toggle: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  color?: string
};

const generateHeader = color => {
  switch (color) {
    case 'success':
      return {
        icon: 'mdi-check'
      };
    case 'danger':
      return {
        icon: 'mdi-alert-circle-outline'
      };
    default:
      return {
        icon: 'mdi-alert-circle-outline'
      };
  }
};

const CustomToast = (props: CustomToastProps) => {
  const {children, toggle, color, onMouseEnter, onMouseLeave} = props;
  return (
    <Toast
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-delay='10000'
      className={styles.customToast}
    >
      <ToastHeader
        className={styles.customToastHeader}
        toggle={toggle}
        icon={
          <span
            className={classNames(
              `mdi mdi--sm text-${color}`,
              generateHeader(color).icon
            )}
          />
        }
      >
        {children}
      </ToastHeader>
    </Toast>
  );
};

CustomToast.defaultProps = {
  icon: '',
  color: 'warning'
};

export default CustomToast;

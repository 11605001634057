import { getActivatedRoutes } from '../utils';
import { loader as i18nLoader } from '../../i18n';

export default routes => () => (toState, fromState) => {
  const activatedRoutes = getActivatedRoutes(routes, toState, fromState);

  if (activatedRoutes.length === 0) {
    return Promise.resolve();
  }

  const onActivateHandlers = activatedRoutes
    .map(route => route.i18n || [])
    .reduce((a, b) => a.concat(b))
    .map(i18nLoader);
  return Promise.all(onActivateHandlers);
};

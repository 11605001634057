import HmacSHA256 from 'crypto-js/hmac-sha256';
import getProfileDetails from '~/_graphql/queries/get-profile-details.graphql';
import apolloClient from '../../apollo';

const carrotQuest = (codeId, userAuthKey) => {
  let isConnected = false;
  return () => () =>
    new Promise(resolve => {
      const { carrotquest } = window;
      if (carrotquest && !isConnected) {
        carrotquest.connect(codeId);
        apolloClient.watchQuery({ query: getProfileDetails, name: 'data' }).subscribe(({ data, loading }) => {
          if (!loading && carrotquest) {
            const { login } = data;
            const { id } = login;
            const { firstName, lastName, email, phone } = login.profile;
            let fullName = firstName;
            if (lastName) {
              fullName += ` ${lastName}`;
            }

            isConnected = true;

            carrotquest.auth(id, HmacSHA256(id, userAuthKey).toString());

            carrotquest.identify({
              $name: fullName,
              $email: email,
              $phone: phone
            });
          }
        });
      }
      resolve();
    });
};

export default carrotQuest;

// @flow

import type { Translate } from '~/modules/i18n/types.flow';
import type { Route, Router } from '~/modules/router/types.flow';

import React from 'react';
import { compose } from 'recompose';
import withErrorBoundary from './_hocs/with-error-boundary';
import { routeNode } from 'react-router5';
import { NotificationComponent } from './modules/notification-system';
import ErrorPage from './pages/errors';
import './styles/breakpoints.css';
import './styles/reset.css';
import './app.scss';

import { initialStore, Store } from '~/store';

type AppProps = {
  t: Translate,
  route: Route,
  router: Router
};

const App = ({ route, router, t }: AppProps) => {
  if (route && route.name) {
    window.rrr = router;
    const Component = router.getDependencies().getComponent(route.name, '');
    const ComponentWithErrorBoundary = withErrorBoundary(ErrorPage)(Component);

    const store = Object.assign({}, initialStore, {
      route,
      router,
      t
    });

    const Page = Component ? (
      <Store.Provider value={store}>
        <ComponentWithErrorBoundary key={route.meta.id} />
      </Store.Provider>
    ) : (
      <ErrorPage code={500} key={route.meta.id} />
    );

    return (
      <>
        {Page}
        {NotificationComponent}
      </>
    );
  }

  const state = router.getState();

  if (state?.is401Error || state?.is503Error) {
    return null;
  }

  return <ErrorPage code={state?.code} errorText={state?.code !== 503 ? state?.error : undefined} />;
};

export default compose(routeNode(''))(App);

import * as yup from 'yup';

export function yupLocale() {
  yup.setLocale({
    mixed: {
      default: 'Поле не валидно',
      required: 'Обязательное поле'
    },
    number: {
      positive: `Значение должно быть положительным числом`,
      negative: `Значение должно быть отрицательным числом`,
      integer: `Значение должно быть целым числом`,
      // eslint-disable-next-line no-template-curly-in-string
      min: 'Значение должно быть меньше, либо равно ${min}'
    }
  });
}

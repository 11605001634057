export default ({ router, toState, graphQLErrors }) => {
  const is401Error = graphQLErrors.findIndex(({ extensions }) => extensions && extensions.httpCode === 401) > -1;

  const is503Error = graphQLErrors.findIndex(({ extensions }) => extensions && extensions.httpCode === 503) > -1;

  if (graphQLErrors?.[0]?.message) {
    router.setState({
      is503Error,
      is401Error,
      error: graphQLErrors[0].message,
      code: graphQLErrors[0].extensions.httpCode,
      name: ''
    });
  }

  if (is401Error) {
    router.navigate('auth.login', { toState });
  }
};

import create from './create/route';
import edit from './edit/route';
import calculator from './calculator/route';
import getPermissions from '~/_graphql/queries/get-permissions.graphql';

export default {
  name: 'flight-form',
  path: '/flight-form',
  forwardTo: 'trading-desk.flight-form.create',
  i18n: ['trading-desk/flight-form'],
  graphQlPrefetch: () => [
    {
      query: getPermissions,
      variables: {},
      fetchPolicy: 'network-only'
    }
  ],
  loadComponent: () => import('./index'),
  children: [create, edit, calculator]
};

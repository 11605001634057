// @flow

import type { Route } from '~/modules/router/types.flow';
import getFlightTargetings from '~/_components/creative-form/_graphql/queries/get-flight-targetings.graphql';
import getFlightFormats from '~/_components/creative-form/_graphql/queries/get-flight-formats.graphql';
import getCreativeFields from '~/_components/creative-form/_graphql/queries/get-creative-fields.graphql';
import getCategoriesLink from '~/_components/creative-form/_graphql/queries/get-categories-download-link.graphql';
import getCreativeForceShowData from './creative/_components/creative-force-show/_graphql/queries/get-creative-force-show-data.graphql';
import getRuidRoute from './creative/_components/creative-force-show/_graphql/queries/get-ruid-route.graphql';
import getFlight from './_graphql/queries/get-flight.graphql';
import getCatalogue from './_graphql/queries/get-catalogue.graphql';
import getCreativeTemplateList from '~/_components/creative-form/_graphql/queries/get-creative-template-list.graphql';
import editComposition from './editComposition/route';

export default {
  name: 'flight',
  path: '/flight/:flightId?a_start&a_end&a_type',
  forwardTo: 'trading-desk.flight.card',
  graphqlPrefetch: ({ params: { flightId } }: Route) => [
    {
      query: getFlight,
      variables: {
        flightId,
        canViewIncomeSource: false
      },
      fetchPolicy: 'network-only'
    },
    {
      query: getCatalogue,
      variables: {},
      fetchPolicy: 'network-only'
    }
  ],
  i18n: [
    'trading-desk/flight',
    'trading-desk/creative-form',
    'trading-desk/main-menu',
    'trading-desk/statistic',
    'translations'
  ],
  loadComponent: () => import('./index'),
  children: [
    {
      name: 'card',
      path: '/'
    },
    {
      name: 'createCreative',
      path: '/creative/create',
      loadComponent: () => import('./creative/create'),
      i18n: ['trading-desk/creative-form', 'translations'],
      graphqlPrefetch: ({ params: { flightId } }: Route) => [
        {
          query: getFlightTargetings,
          variables: { flightId },
          fetchPolicy: 'network-only'
        },
        {
          query: getFlightFormats,
          variables: { flightId },
          fetchPolicy: 'network-only'
        },
        {
          query: getCategoriesLink
        },
        {
          query: getCreativeTemplateList,
          variables: { flightId }
        }
      ]
    },
    {
      name: 'editCreative',
      path: '/creative/:creativeId/edit',
      loadComponent: () => import('./creative/edit'),
      i18n: ['trading-desk/creative-form', 'translations'],
      graphqlPrefetch: ({ params: { flightId, creativeId } }: Route) => [
        {
          query: getCreativeFields,
          variables: { creativeId },
          fetchPolicy: 'network-only'
        },
        {
          query: getFlightTargetings,
          variables: { flightId },
          fetchPolicy: 'network-only'
        },
        {
          query: getFlightFormats,
          variables: { flightId },
          fetchPolicy: 'network-only'
        },
        {
          query: getCategoriesLink
        }
      ]
    },
    {
      name: 'copyCreative',
      path: '/creative/:creativeId/copy',
      loadComponent: () => import('./creative/copy'),
      i18n: ['trading-desk/creative-form', 'translations'],
      graphqlPrefetch: ({ params: { flightId, creativeId } }: Route) => [
        {
          query: getCreativeFields,
          variables: { creativeId },
          fetchPolicy: 'network-only'
        },
        {
          query: getFlightTargetings,
          variables: { flightId },
          fetchPolicy: 'network-only'
        },
        {
          query: getFlightFormats,
          variables: { flightId },
          fetchPolicy: 'network-only'
        },
        {
          query: getCategoriesLink
        }
      ]
    },
    {
      name: 'massCopy',
      path: '/massCopy',
      loadComponent: () => import('./_components/mass-copy'),
      i18n: ['trading-desk/creative-form', 'translations']
    },
    {
      name: 'forceShowCreative',
      path: '/creative/:creativeId/forceShow',
      loadComponent: () => import('./creative/forceShow'),
      graphqlPrefetch: ({ params: { creativeId } }: Route) => [
        {
          query: getCreativeForceShowData,
          variables: { creativeId },
          fetchPolicy: 'network-only'
        },
        {
          query: getRuidRoute
        }
      ]
    },
    editComposition
  ]
};

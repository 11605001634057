// @flow

import massEdit from './mass-edit/route';
import form from './form/route';

export default {
  name: 'campaign',
  path: '/campaign/:id',
  forwardTo: 'trading-desk.campaign.form',
  i18n: ['trading-desk/campaign'],
  loadComponent: () => import('./index'),
  children: [massEdit, form]
};

import getPermissions from '~/_graphql/queries/get-permissions.graphql';
import apolloClient from '../../apollo';
import { getActivatedRoutes } from '../utils';
import graphqlExceptionHandling from './graphql-exception-handling';

const CODE_ERROR = 403;

const CUSTOM_ERROR = {
  message: 'У вас не хватает прав доступа',
  extensions: {
    httpCode: CODE_ERROR
  }
};

const hasPermissions = (routes, router) => () => (toState, fromState) => {
  const handler = new Promise((resolve, reject) => {
    const activatedRoutes = getActivatedRoutes(routes, toState, fromState);

    const permissionsRules = activatedRoutes.filter(r => r.hasPermissions).map(r => r.hasPermissions);

    if (permissionsRules.length === 0) {
      resolve();
      return;
    }

    apolloClient.query({ query: getPermissions }).then(res => {
      const can = permissionsRules.reduce((acc, has) => (has ? has(res.data.login.permissions) && acc : acc), true);
      return can ? resolve() : reject(CUSTOM_ERROR);
    });
  });

  handler.catch(graphQLErrors => graphqlExceptionHandling({ graphQLErrors: [graphQLErrors], router, toState }));
  return handler;
};

export default hasPermissions;

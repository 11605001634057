// @flow

import React from 'react';
import classNames from 'classnames';
import CustomToast from '~/_components/toast';
import Timer from './helpers';

type NotificationItemProps = {
  notification: any,
  onRemove?: (id: number) => void,
  allowHTML?: boolean
};

type NotificationItemState = {};

class NotificationItem extends React.Component<
  NotificationItemProps,
  NotificationItemState
> {
  static defaultProps = {
    onRemove: () => {},
    allowHTML: false
  };

  constructor(props: NotificationItemProps) {
    super(props);

    this.notificationTimer = null;
    this.isMount = false;
    this.removeCount = 0;
  }

  componentDidMount() {
    const self = this;
    const {notification} = this.props;
    this.isMount = true;

    if (notification.autoDismiss) {
      this.notificationTimer = new Timer(() => {
        self.hideNotification();
      }, notification.autoDismiss * 1000);
    }
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  onTransitionEnd = () => {
    if (this.removeCount > 0) return;
    this.removeCount += 1;
    this.removeNotification();
  };

  dismiss = () => {
    const {
      notification: {dismissible}
    } = this.props;
    if (dismissible) {
      this.hideNotification();
    }
  };

  removeNotification = () => {
    const {
      onRemove,
      notification: {uid}
    } = this.props;

    if (onRemove) {
      onRemove(uid);
    }
  };

  hideNotification = () => {
    if (this.notificationTimer) {
      this.notificationTimer.clear();
    }

    this.removeNotification();
  };

  defaultAction = (event: SyntheticEvent<HTMLButtonElement>) => {
    const {notification} = this.props;

    event.preventDefault();
    this.hideNotification();
    if (typeof notification.action.callback === 'function') {
      notification.action.callback();
    }
  };

  handleMouseEnter = () => {
    const {notification} = this.props;
    if (notification.autoDismiss) {
      this.notificationTimer.pause();
    }
  };

  handleMouseLeave = () => {
    const {notification} = this.props;
    if (notification.autoDismiss) {
      this.notificationTimer.resume();
    }
  };

  handleNotificationClick = () => {
    const {
      notification: {dismissible}
    } = this.props;

    if (['both', 'click', true].includes(dismissible)) {
      this.dismiss();
    }
  };

  allowHTML = (__html: string) => ({__html});

  notificationTimer: any;

  isMount: boolean;

  removeCount: number;

  render() {
    const {notification, allowHTML} = this.props;

    const actionButton = [];
    let message = null;

    const isDismissible = ['both', 'click', true].includes(
      notification.dismissible
    );

    const className = classNames(
      'notification-system__alert',
      'alert',
      `alert-${notification.level}`,
      {
        'alert-not-dismissible': notification.dismissible === 'none',
        'alert-dismissible': isDismissible
      }
    );

    if (notification.message) {
      if (allowHTML) {
        message = (
          <div
            className='alert-message'
            dangerouslySetInnerHTML={this.allowHTML(notification.message)}
          />
        );
      } else {
        message = <div className='alert-message'>{notification.message}</div>;
      }
    }

    if (notification.action) {
      let buttonClassName = 'btn-';

      if (notification.action.outline) {
        buttonClassName += 'outline-';
      }
      buttonClassName += notification.level;

      actionButton.push(
        <div className=''>
          <button
            className={classNames(`btn btn-sm mr-1 mt-2 ${buttonClassName}`)}
            type='button'
            onClick={this.defaultAction}
          >
            {notification.action.label}
          </button>
        </div>
      );
    }

    if (notification.children) {
      actionButton.push(notification.children);
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <CustomToast
        className={className}
        color={notification.level}
        toggle={isDismissible ? this.dismiss : this.handleNotificationClick}
        button={actionButton}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {message}
        {actionButton}
      </CustomToast>
    );
  }
}

NotificationItem.defaultProps = {
  onRemove() {},
  allowHTML: false
};

export default NotificationItem;

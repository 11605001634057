// @flow

export default {
  // Levels
  levels: {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    light: 'light',
    dark: 'dark'
  },

  // Notification defaults
  notification: {
    title: null,
    message: null,
    level: null,
    autoDismiss: 5,
    dismissible: 'both',
    actions: []
  }
};

// @flow

import i18next from 'i18next';
import Cache from 'i18next-localstorage-cache';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import filesize from 'filesize';
import numeral from 'numeral/src/numeral';
import { initReactI18next } from 'react-i18next';
import { yupLocale } from './yupLocation';
// TODO после появления второго языка нужно ввести подгрузку
import 'moment/locale/ru';

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'тыс.',
    million: 'млн.',
    billion: 'млрд.',
    trillion: 'трлн.'
  },
  ordinal: () => '.',
  currency: {
    symbol: 'руб.'
  }
});

i18next.languages = ['ru'];

i18next
  .use(Cache)
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: 'ru',
      referenceLng: 'ru',
      ns: ['translations', 'common', 'app', 'errors'],
      debug: process.env.NODE_ENV !== 'production',
      interpolation: {
        escapeValue: false,
        format: (value, format) => {
          if (value instanceof Date) return moment(value).format(format);
          if (format === 'currency') return numeral(value).format('0.[00]');
          if (format === 'ctr') return numeral(value).format('0.[00]');
          if (format === 'bytes') {
            const size = filesize(value, { output: 'array' });
            const number = numeral(size[0]).format(`0.[00]`);
            const suffixe = i18next.t(`translations:filesize.${size[1]}`);
            return `${number} ${suffixe}`;
          }
          if (format === 'number') {
            const formatter = new Intl.NumberFormat('ru');
            return formatter.format(value);
          }
          return value;
        }
      },
      react: {
        useSuspense: false
      },
      saveMissing: false,
      backend: {
        loadPath: `${process.env.PUBLIC_URL || ''}/locales/{{lng}}/{{ns}}.json`
      }
    },
    (err, t) => {
      document.title = t('app:title');
      yupLocale();
    }
  );

/**
 * Загрузка I18N namespace.
 * @param {string} namespaces - namespaces для загрузки.
 * @return {promise} promise с результатом загрузки.
 */
const loader = (namespace: string) => i18next.loadNamespaces(namespace);

/**
 * I18N.
 * @module i18n
 */
export { loader };
export default i18next;

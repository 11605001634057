// @flow

import moment from 'moment';

export const LOAD_PER_PAGE = 10;

export const dateVariables = (params: {}) => {
  const variables = {};
  if (params.creativeId) {
    variables.id = params.creativeId;
    variables.groupBy = 'Creative';
  } else if (params.flightId) {
    variables.id = params.flightId;
    variables.groupBy = 'Flight';
  } else if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.wssid = params.wssid;
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  switch (params.group) {
    case 'day':
      variables.byTime = 'Day';
      break;
    case 'week':
      variables.byTime = 'Week';
      break;
    case 'month':
      variables.byTime = 'Month';
      break;
    default:
      break;
  }
  return variables;
};

export const campaignVariables = (params: {}) => {
  const variables = {};
  if (params.advertiserId) {
    variables.id = params.advertiserId;
    variables.groupBy = 'Campaign';
  }
  variables.wssid = params.wssid;
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  return variables;
};

export const flightVariables = (params: {}) => {
  const variables = {};
  if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.wssid = params.wssid;
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  return variables;
};

export const creativeVariables = (params: {}) => {
  const variables = {};
  if (params.flightId) {
    variables.id = params.flightId;
    variables.groupBy = 'Flight';
  } else if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.wssid = params.wssid;
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  return variables;
};

export const websiteVariables = (params: {}) => {
  const variables = {};
  if (params.creativeId) {
    variables.id = params.creativeId;
    variables.groupBy = 'Creative';
  } else if (params.flightId) {
    variables.id = params.flightId;
    variables.groupBy = 'Flight';
  } else if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.wssid = params.wssid;
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  return variables;
};

export const geoVariables = (params: {}) => {
  const variables = {};
  if (params.creativeId) {
    variables.id = params.creativeId;
    variables.groupBy = 'Creative';
  } else if (params.flightId) {
    variables.id = params.flightId;
    variables.groupBy = 'Flight';
  } else if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  switch (params.group) {
    case 'city':
      variables.geoGroupBy = 'City';
      break;
    case 'region':
      variables.geoGroupBy = 'Region';
      break;
    case 'country':
      variables.geoGroupBy = 'Country';
      break;
    default:
      break;
  }
  return variables;
};

export const socialDemographyVariables = (params: {}) => {
  const variables = {};
  if (params.creativeId) {
    variables.id = params.creativeId;
    variables.groupBy = 'Creative';
  } else if (params.flightId) {
    variables.id = params.flightId;
    variables.groupBy = 'Flight';
  } else if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  return variables;
};

export const segmentVariables = (params: {}) => {
  const variables = {};
  if (params.creativeId) {
    variables.id = params.creativeId;
    variables.groupBy = 'Creative';
  } else if (params.flightId) {
    variables.id = params.flightId;
    variables.groupBy = 'Flight';
  } else if (params.campaignId) {
    variables.id = params.campaignId;
    variables.groupBy = 'Campaign';
  }
  variables.period = {
    start: moment(params.period.from).format('YYYY-MM-DD'),
    end: moment(params.period.to).format('YYYY-MM-DD')
  };
  variables.bidType = params.bidType;
  return variables;
};

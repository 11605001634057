// @flow
/* eslint-disable import/no-cycle */
/* eslint-disable new-cap */

import { ApolloClient, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';
import { createUploadLink } from './lib/apollo-upload-client';
import clientState from './client-state';
import possibleTypes from './possibleTypes.json';
import { onError } from '@apollo/client/link/error';

const locationsWithoutAuth = [
  '/',
  '/login/',
  '/login',
  '',
  '/login/reset-password',
  '/login/reset-password/',
  '/registration',
  '/registration/',
  '/restoration',
  '/restoration/'
];

const authLink = setContext(({ operationName }, { headers }) => {
  // Для клиентских стейтов и запроса авторизации x-api-token простовлять не нужно
  const authHeaders =
    Cookies.get('token') && ['getSession', 'login'].indexOf(operationName) === -1
      ? { 'x-api-token': Cookies.get('token') }
      : {};

  return {
    headers: {
      ...headers,
      ...authHeaders
    }
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      if (error.extensions.httpCode === 401 && !locationsWithoutAuth.includes(window.location.pathname)) {
        window.location.href = `/login?referer=${encodeURIComponent(new URL(window.location.href).pathname)}`;
      }
      if (error.extensions.httpCode === 503) {
        window.location.href = '/';
      }
    });
});

const httpLink = new createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT || '',
  fetchOptions: {
    method: 'POST',
    credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
  }
});

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      merge: true,
      fields: {
        catalogue: {
          merge: true
        },
        ui: {
          merge: true
        },
        inventory: {
          merge: true
        }
      }
    },
    ProductUIQuery: {
      merge: true
    },
    InventoryMutation: {
      merge: true
    }
  }
});
const link = ApolloLink.from([authLink, errorLink, httpLink]);

const client = new ApolloClient({
  cache,
  link,
  resolvers: clientState.resolvers
});

export default client;

import login from './login/route';
import registration from './registration/route';
import restoration from './restoration/route';
import resetPassword from './reset-password/route';

export default {
  name: 'auth',
  path: '/login',
  forwardTo: 'auth.login',
  i18n: ['app'],
  loadComponent: () => import('./index'),
  children: [login, registration, resetPassword, restoration]
};

import React from 'react';

const withErrorBoundary = ErrorBoundary => Component =>
  class extends React.Component {
    state = {hasError: false};

    static getDerivedStateFromError(error) {
      console.error(error);

      return {hasError: true};
    }

    render() {
      const {hasError} = this.state;

      if (hasError) {
        return <ErrorBoundary />;
      }

      return <Component {...this.props} />;
    }
  };

export default withErrorBoundary;

// @flow

import getHelpCategories from './_graphql/queries/get-help-categories.graphql';

export default {
  name: 'help',
  path: '/help*slugs',
  loadComponent: () => import('./index'),
  graphqlPrefetch: () => [{ query: getHelpCategories, fetchPolicy: 'network-only' }],
  encodeParams: ({ slugs }: { slugs: Array<string> }) => {
    if (Array.isArray(slugs)) {
      slugs = slugs.join('__');
    }
    return {
      // replace __, that is separator of slugs, to url symbol '/'
      slugs: slugs ? `/${slugs.replace(/__/g, '/')}` : ''
    };
  },
  decodeParams: ({ slugs }: { slugs: string }) => {
    // replace url back to string with '__' as separator
    // first replace function call replaces first '/' with nothing
    const slugsPrepeared: Array<string> = slugs.replace(/^\//, '').replace(/\//g, '__');
    // example result of replacing:
    // input: /chezh_republic/ankundingview
    // output: chezh_republic__ankundingview
    return {
      slugs: slugsPrepeared
    };
  },
  i18n: ['trading-desk/help'],
  defaultParams: {
    slugs: ''
  }
};

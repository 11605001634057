/**
 * Polyfills
 */
import './wdyr';
import 'formdata-polyfill';
import 'core-js/features/array/find';

import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { RouterProvider } from 'react-router5';
import apolloClient from './modules/apollo';
import App from './app';
import './modules/i18n';
import { unregister } from './registerServiceWorker';
import createRouter from './modules/router';

dayjs.locale('ru');

const router = createRouter();

router.start(() => {
  App.whyDidYouRender = true;
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </ApolloProvider>,
    document.getElementById('root')
  );
});

unregister();
